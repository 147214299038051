import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import InfoCreditCheck from "../../../components/Landers/InfoCreditCheck";
import SEO from "../../../components/Landers/SEO";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import Testimonials from "../../../components/Landers/Testimonials";
import WhatsUnique from "../../../components/Landers/WhatsUnique";
import Footer from "../../../components/Landers/Footer";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import DataInfo from "../../../components/Data";

const steps = [
  {
    h5: "Initiate Credit Score Verification",
    p: "Collect the PAN card details of the candidate",
  },
  {
    h5: "Submit the required details",
    p: "SpringVerify uploads the details to the Credit Bureau",
  },
  {
    h5: "Gain insights",
    p:
      "Get the credit score of the candidate effortlessly with real time updates",
  },
];

const testimonials = [
  {
    name: "Rupali M ",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. It provides real-time updates making our HR job easier. The entire process is so seamless saving us a lot of time, 
    effort and resources in the process. `,
  },
  {
    name: "Manvi K ",
    company: "Inshorts",
    p: `One thing that really stands-out is their GREAT customer service. The Dedicated Account Manager responds in a very timely manner to any question at the drop of a hat and you really can't ask for more than that!`,
  },
  {
    name: "Harshavardhan N ",
    company: "Razorsharp Technologies Pvt. Ltd.",
    p: `SpringVerify is very simple to use. Initiating checks are extremely easy compared to previous product I have used so far. Their candidate and the HR portals are by far the best. 
    Our company relies on SpringVerify everytime we run background checks on candidates. `,
  },
];

export default () => (
  <div className={styles.CreditCheck}>
    <SEO
      title="Credit Background Verification Service| SpringVerify India"
      desc="Verify the credit score of your candidate using SpringVerify's background verification services for the best turnaround times."
      url="https://in.springverify.com/screenings/credit-check/"
      img_url="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+(Credit+Checks).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        className={styles.CheckBanner}
        h1="Reliable Candidate Credit Score Verifications"
        p="Get a comprehensive credit score of your candidate to know if they have been involved in financial fraud, payment default, or deferred payments."
        img="https://assets-sv-in.gumlet.io/LP/credit-check/banner-credit-check.svg"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="credit-check/illustration.png"
        h2="Need for Credit Checks"
        p="Individuals with a bad track record of financial management can directly have an impact on your
        brand image and reputation. Thus, running credit checks can be helpful for:"
        ul={[
          "Employees who manage your company finances",
          "Accounting and Stakeholder management",
          "Employees in the banking and fintech sector",
          "Hiring for Executive-level and Financial roles",
        ]}
      />
    </section>
    <InfoCreditCheck data={DataInfo.InfoCreditCheck} />
    <ThreeSteps
      subHeader={"\n"}
      h2={"How SpringVerify helps you run \ncredit checks?"}
      steps={steps}
      candidate="https://assets-sv-in.gumlet.io/LP/credit-check/three-steps.png"
      path="credit-check"
    />
    <WhatsUnique
      path="credit-check/"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Tailored solutions based on \nyour requirements",
        "Powered by AI and Blockchain",
        "Remote-friendly and \ncontactless verifications",
        "Compliant with \nISO:27001:9000 PCI, and more",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="credit-check/getstarted.svg"
      h3={`Ready to get started \nwith us?`}
      p="Get thorough insights into your candidate's financial history by running credit verifications"
    />
    <Footer />
  </div>
);
